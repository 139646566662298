import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TheMenuCard from "../cards/the-menu-card"

/**
 *
 */
class CateringPage extends React.Component {
    /**
     *
     */
    handleCallForCateringClick = () => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: "click-button-call-for-catering"
            });
        }
    };

    /**
     *
     * @returns {XML}
     */
    render() {
        return (
            <Layout>
                <SEO title="Catering"/>

                <div className="container mt-3">
                    <h1 className="text-center">Catering</h1>

                    <a className="btn btn-block btn-primary" href="tel:19252703482"
                       onClick={this.handleCallForCateringClick}>Call for Catering</a>

                    <div className="my-3">
                        <TheMenuCard></TheMenuCard>
                    </div>

                    <a className="btn btn-block btn-primary" href="tel:19252703482"
                       onClick={this.handleCallForCateringClick}>Call for Catering</a>

                    <div className="text-center mt-5">
                        <Link className="btn btn-secondary" to="/">Back Home</Link>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default CateringPage
